<template>
  <div>
    <CCard>
      <CCardBody>
        <SSelectCustomer
          :value.sync="customer_id"
          label="Customer"
          class="mb-2"
        />
        <TButton
          class="w-100 mt-2"
          content="Filter"
          @click="onFilter"
          :disabled="loading || !customer_id"
        />
      </CCardBody>
    </CCard>
    <TTableSimple
      :items="list"
      :fields="fields"
      :loading="loading"
      :options="{ striped: true, itemsPerPage: 15, sorter: true }"
      :pagination="true"
      class="mt-3"
    >
      <template #_="{ index }">
        <td>
          <span>
            {{ ++index }}
          </span>
        </td>
      </template>
      <template #quantity="{ item }">
        <td>
          <TMessageNumber :value="item.quantity" class="h6" />
        </td>
      </template>
      <template #product="{ item }">
        <td>
          <SCardProductItems
            v-if="item"
            :item="item.product"
            widthAuto
            resource="/warehouse/management/products"
          />
        </td>
      </template>
    </TTableSimple>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          _style: "width: 40px; max-width: 40px",
        },
        {
          key: "product",
          label: "Product",
          _style: "width: 450px; min-width: 450px",
        },
        {
          key: "quantity",
          label: "Quantity",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
          sorter: true,
        },
      ],
      customer_id: "",
    };
  },
  destroyed() {
    this.$store.commit("warehouse.statistics_quantity_in_inventory_user.purge");
  },
  computed: {
    ...mapGetters({
      list: "warehouse.statistics_quantity_in_inventory_user.list",
      loading: "warehouse.statistics_quantity_in_inventory_user.loading",
    }),
  },
  methods: {
    onFilter() {
      this.$store.dispatch(
        "warehouse.statistics_quantity_in_inventory_user.apply-query",
        { customer_id: this.customer_id }
      );
    },
  },
};
</script>
